import vote from "./server/api/vote";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  messages: {
    en: {
      promotedCoins: 'Promoted Coins',
      advertiseObj: {
        title: 'Advertise with us',
        description: 'We are always looking for new and exciting opportunities to grow. If you have any ideas or suggestions, please get in touch. We are always happy to hear from you!',
        button: 'Submit Project',
        listing: 'Listing',
        listingDescription: 'Reach thousands of crypto enthusiasts by submitting your projects!',
        discordNotification: 'Discord Notification',
        coolPages: 'Cool looking project page',
        telegramNotification: 'Telegram Notification',
        cmcComment: 'Comment on CoinMarketCap',
        free: 'FREE',
        promotedSpot: 'Promoted spot',
        days: 'days',
        anyCurrency: 'Any Currency',
        banners: 'Banners',
        contact: 'Contact us',
        normal: 'Normal',
        mostPopular: 'Most Popular',
        bestDeal: 'Best Deal',
      },
      toast: {
        contract: 'Contract address copied to clipboard!',
        cookie: 'We use Cookies',
        cookieDescription: 'CryptoProjectIO uses cookies to improve the user experience on our website. You can manage cookie preferences from your browser settings.',
        comingSoon: 'Coming Soon',
        voteError: 'Error occurred while voting',
        voteSuccess: 'Thank you for voting! You can vote again in 15 minutes',
        voteFail: 'Already voted! You can vote once every 15 minutes',
        voteDefault: 'Voting failed',
      },
      workingOnIt: 'Working on it',
      educational: 'Educational',
      funfact: 'Fun Fact',
      login: 'Login / Register',
      watchlist: 'Watchlist',
      homepage: 'Homepage',
      news: 'News',
      newsDescription: 'Get the latest crypto news directly from users, with authentic news and discussions from the global crypto community.',
      coins: 'Coins',
      coinsDescription: 'Explore our selection of meme coins, where investing meets digital culture and emerging trends in cryptocurrencies.',
      NFTs: 'NFTs',
      advertise: 'Advertise with us',
      submitCoin: 'Submit Coin',
      submitNews: 'Submit News',
      submitNFTs: 'Submit NFTs',
      logout: 'Logout',
      description: 'Description',
      topGainers: 'Top Gainers',
      topVotedToday: 'Top Voted Today',
      newListings: 'New Listings',
      chain: 'Chain',
      h1: '1H',
      h6: '6H',
      h24: '24H',
      marketcap: 'Market Cap',
      launch: 'Launch',
      votesToday: 'Votes Today',
      totalVotes: 'Total Votes',
      added: 'Added',
      recentNews: 'Recent News',
      policy: 'Policy',
      terms: 'Terms',
      contact: 'Contact',
      contract: 'Contract Address',
      submit: 'Submit',
      viewAll: 'View All',
      price: 'Price',
      liquidity: 'Liquidity',
      sale: 'Sale',
      preSale: 'Pre-Sale',
      priceChange: 'Price Change',
      vote: 'Vote',
      website: 'Website',
      submitCoinForm: {
        title: 'Submit Coin Project',
        pageDescription: 'Reach thousands of crypto enthusiasts by submitting your projects!',
        name: 'Name',
        symbol: 'Symbol',
        description: 'Project Description',
        descriptionPlaceholder: 'Write a short description of the project',
        images: 'Project Image',
        website: 'Website',
        twitter: 'Twitter / X',
        discord: 'Discord',
        telegram: 'Telegram',
        saleDate: 'Sale Date',
        preSaleStartDate: 'Pre-Sale Start Date',
        preSaleEndDate: 'Pre-Sale End Date',
        softcap: 'Softcap',
        hardcap: 'Hardcap',
        datePlaceholder: 'Choose Date',
        transaction: 'Transaction Hash',
        listingFee: 'Listing Fee is 1SOL, send here: HPAn1qa4hwnuRGpeDTrWyvEt4gW6Dyd1hHAKxTBHaWmj',
      },
      submitNewsForm: {
        title: 'Submit News / Article',
        pageDescription: 'Reach thousands of crypto enthusiasts by submitting your article/news!',
        formTitle: 'Title',
        titlePlaceholder: 'Article Title',
        author: 'Author',
        authorPlaceholder: 'Your Name',
        authorLink: 'Author Link',
        company: 'Company/Coin Name',
        companyPlaceholder: 'Company/Coin Name',
        companyLink: 'Company/Coin Link',
        content: 'Content',
        image: 'Image',
      },
      userForms: {
        welcome: 'Welcome back!',
        noAccount: 'Don\'t have an account?',
        signUp: 'Sign Up',
        email: 'Email',
        emailPlaceholder: 'Enter your email',
        password: 'Password',
        passwordPlaceholder: 'Enter your password',
        forgotPassword: 'Forgot Password?',
        login: 'Login',
        bySigningIn: 'By signing in, you agree to our',
        register: 'Register',
        alreadyHaveAccount: 'Already have an account?',
        repeatPassword: 'Repeat Password',
        repeatPasswordPlaceholder: 'Repeat your password',
        continueWithGoogle: 'Continue with Google',
        continueWithTwitter: 'Continue with Twitter / X',
        resetPassword: 'Reset Password',
        backTo: 'Back to',
        sendEmail: 'Send Email',
        terms: 'Terms Of Service',
      },
      tables: {
        topGainers: 'Top Gainers / 24H',
        topVotedToday: 'Top Voted Today',
        newListings: 'New Listings',
        recentNews: 'Recent News',
        watchlistFavorites: 'Watchlist Favorites',
      }
    },
    ru: {
      promotedCoins: 'Продвигаемые монеты',
      advertiseObj: {
        title: 'Разместить проект',
        description: 'Мы всегда ищем новые и захватывающие возможности для развития. Если у вас есть идеи или предложения, пожалуйста, свяжитесь с нами. Мы всегда рады услышать вас!',
        button: 'Разместить проект',
        listing: 'Предложение',
        listingDescription: 'Поднимаем тысячи криптопользователей, размещая свои проекты!',
        discordNotification: 'Уведомление в Discord',
        coolPages: 'Классная страница проекта',
        telegramNotification: 'Уведомление в Telegram',
        cmcComment: 'Комментарий для CoinMarketCap',
        free: 'БЕСПЛАТНО',
        promotedSpot: 'Рекламируемое место',
        days: 'дней',
        anyCurrency: 'Любая валюта',
        banners: 'Баннеры',
        contact: 'Свяжитесь с нами',
        normal: 'Обычный',
        mostPopular: 'Самый популярный',
        bestDeal: 'Лучшая сделка',
      },
      toast: {
        contract: 'Адрес контракта скопирован в буфер!',
        cookie: 'Используем куки',
        cookieDescription: 'CryptoProjectIO использует файлы cookie для улучшения работы пользователей на нашем сайте. Вы можете управлять предпочтениями в отношении файлов cookie в настройках вашего браузера.',
        comingSoon: 'Скоро',
        voteError: 'Произошла ошибка при голосовании',
        voteSuccess: 'Спасибо за голос! Вы можете голосовать еще раз через 15 минут',
        voteFail: 'Вы уже голосовали! Вы можете голосовать раз в 15 минут',
        voteDefault: 'Голосование не удалось',
      },
      workingOnIt: 'В работе',
      educational: 'Обучение',
      funfact: 'Случайная факт',
      login: 'Вход / Регистрация ',
      watchlist: 'Список наблюдения',
      homepage: 'Домашняя страница',
      news: 'Новости',
      newsDescription: 'Получите свежие новости криптовалюты и социальные новости из криптовалютного сообщества. ',
      coins: 'Монеты',
      coinsDescription: 'Исследуйте наш выбор мемов, где инвестиции в мемовые монеты и современные тенденции в криптовалюте.',
      NFTs: 'NFT',
      advertise: 'Реклама с нами',
      submitCoin: 'Отправить монету',
      submitNews: 'Отправить новость',
      submitNFTs: 'Отправить NFT',
      logout: 'Выход',
      description: 'Описание',
      topGainers: 'Топ популярных',
      topVotedToday: 'Топ проголосовавших сегодня',
      newListings: 'Новые объявления',
      chain: 'Сеть',
      h1: '1H',
      h6: '6H',
      h24: '24H',
      marketcap: 'Рыночная капитализация',
      launch: 'Запуск',
      votesToday: 'Голоса сегодня',
      totalVotes: 'Всего голосов',
      added: 'Добавлен',
      recentNews: 'Последние новости',
      policy: 'Политика',
      terms: 'Условия ',
      contact: 'Контакты',
      contract: 'Адрес контракта',
      submit: 'Отправить',
      viewAll: 'Посмотреть все',
      price: 'Цена',
      liquidity: 'Ликвидность',
      sale: 'Продажа',
      preSale: 'Предпродажа',
      priceChange: 'Изменение цены',
      vote: 'Голос',
      website: 'Сайт',
      submitCoinForm: {
        title: 'Отправить монету проекта',
        pageDescription: 'Поднимайте миллионы любителей криптовалюты!',
        name: 'Название',
        symbol: 'Символ',
        description: 'Описание проекта',
        descriptionPlaceholder: 'Напишите краткое описание проекта',
        images: 'Изображения проекта',
        website: 'Сайт',
        twitter: 'Twitter / X',
        discord: 'Discord',
        telegram: 'Telegram',
        saleDate: 'Дата продажи',
        preSaleStartDate: 'Дата начала предпродажи',
        preSaleEndDate: 'Дата окончания предпродажи',
        softcap: 'Softcap',
        hardcap: 'Hardcap',
        datePlaceholder: 'Выберите дату',
        transaction: 'Транзакция',
        listingFee: 'Плата за листинг составляет 1 SOL, отправьте сюда: HPAn1qa4hwnuRGpeDTrWyvEt4gW6Dyd1hHAKxTBHaWmj',
      },
      submitNewsForm: {
        title: 'Отправить новость',
        pageDescription: 'Поднимайте миллионы любителей криптовалюты!',
        formTitle: 'Заголовок',
        titlePlaceholder: 'Заголовок',
        author: 'Автор',
        authorPlaceholder: 'Ваше имя',
        authorLink: 'Ссылка на автора',
        company: 'Компания / Название монеты',
        companyPlaceholder: 'Компания / Название монеты',
        companyLink: 'Ссылка на компанию',
        content: 'Содержание',
        image: 'Изображение',
      },
      userForms: {
        welcome: 'Добро пожаловать',
        noAccount: 'Нет аккаунта?',
        signUp: 'Регистрация',
        login: 'Вход',
        forgotPassword: 'Забыли пароль?',
        resetPassword: 'Сбросить пароль',
        password: 'Пароль',
        email: 'Электронная почта',
        emailPlaceholder: 'Введите ваш адрес электронной почты',
        passwordPlaceholder: 'Введите ваш пароль',
        bySigningIn: 'Подписавшись, вы соглашаетесь с нашими',
        register: 'Регистрация',
        alreadyHaveAccount: 'Уже есть аккаунт?',
        repeatPassword: 'Повторите пароль',
        repeatPasswordPlaceholder: 'Повторите ваш пароль',
        continueWithGoogle: 'Продолжить с Google',
        continueWithTwitter: 'Продолжить с Twitter / X',
        backTo: 'Вернуться к',
        sendEmail: 'Отправить почту',
        terms: 'Условия',
      },
      tables: {
        topGainers: 'Топ Геймеров / 24H',
        topVotedToday: 'Топ Голосов сегодня',
        newListings: 'Новые объявления',
        recentNews: 'Последние новости',
        watchlistFavorites: 'Избранное',
      }
    },
    zh: {
      promotedCoins: '推广币种',
      advertiseObj: {
        title: '宣传',
        description: '我们一直在寻找新的、令人兴奋的发展机会。如果您有任何想法或建议，请与我们联系。我们随时欢迎您的来信！',
        button: '提交项目',
        listing: '上市',
        listingDescription: '通过提交您的项目，接触成千上万的加密货币爱好者！',
        discordNotification: 'Discord 通知',
        coolPages: '看起来很酷的项目页面',
        telegramNotification: 'Telegram 通知',
        cmcComment: '评论 CoinMarketCap',
        free: '免费',
        promotedSpot: '宣传点',
        days: '天数',
        anyCurrency: '任何货币',
        banners: '横幅',
        contact: '联系我们',
        normal: '普通',
        mostPopular: '最受欢迎',
        bestDeal: '最佳价格',
      },
      toast: {
        contract: '合约地址已复制到剪贴板!',
        cookie: '我们使用 Cookie',
        cookieDescription: 'CryptoProjectIO 使用 Cookie 来改善用户体验。您可以在浏览器设置中管理 Cookie 配置。',
        comingSoon: '即将上线',
        voteError: '投票失败',
        voteSuccess: '感谢您的投票！您可以在 15 分钟内再次投票',
        voteFail: '已投票！您可以在 15 分钟内再次投票',
        voteDefault: '投票失败',
      },
      workingOnIt: '正在进行中',
      educational: '教育',
      funfact: '有趣事实',  
      login: '登录 / 注册 ',
      watchlist: '观察名单',
      homepage: '主页',
      news: '新闻',
      newsDescription: '从用户获取的最新加密新闻，包括认证的新闻和社区中的讨论。',
      coins: '币种',
      coinsDescription: '探索我们的币种选择，其中投资与数字文化和创新趋势在加密货币中融合。',
      NFTs: 'NFT',
      advertise: '与我们合作',
      submitCoin: '提交币种',
      submitNews: '提交新闻',
      submitNFTs: '提交NFT',
      logout: '退出',
      description: '描述',
      topGainers: '热门币种',
      topVotedToday: '今日热门投票',
      newListings: '最新广告',
      chain: '链',
      h1: '1H',
      h6: '6H',
      h24: '24H',
      marketcap: '市值',
      launch: '发行',
      votesToday: '今日投票',
      totalVotes: '总票数',
      added: '添加',
      recentNews: '最新新闻',
      policy: "隐私政策",
      terms: "使用条款",
      contact: "联系",
      contract: "合约地址",
      submit: "提交",
      viewAll: "查看所有",
      price: "价格",
      liquidity: "流动性",
      sale: "销售",
      preSale: "预销售",
      priceChange: "价格变化",
      vote: "投票",
      website: "网站",
      submitCoinForm: {
        title: "提交币种项目",
        pageDescription: "从用户获取的最新加密新闻，包括认证的新闻和社区中的讨论。",
        name: "名称",
        symbol: "符号",
        description: "项目描述",
        descriptionPlaceholder: "写下项目的简短描述",
        images: "项目图片",
        website: "网站",
        twitter: "Twitter",
        discord: "Discord",
        telegram: "Telegram / X",
        saleDate: "销售日期",
        preSaleStartDate: "预销售开始日期",
        preSaleEndDate: "预销售结束日期",
        softcap: "Softcap",
        hardcap: "Hardcap",
        datePlaceholder: "选择日期",
        transaction: "交易",
        listingFee: "上市费用为 1 SOL, 在此发送：HPAn1qa4hwnuRGpeDTrWyvEt4gW6Dyd1hHAKxTBHaWmj",
      },
      submitNewsForm: {
        title: "提交新闻 / 文章",
        pageDescription: "从用户获取的最新加密新闻，包括认证的新闻和社区中的讨论。",
        formTitle: "标题",
        titlePlaceholder: "文章标题",
        author: "作者",
        authorPlaceholder: "您的名字",
        authorLink: "作者链接",
        company: "公司 / 币种名称",
        companyPlaceholder: "公司 / 币种名称",
        companyLink: "公司 / 币种链接",
        content: "内容",
        image: "图片",
      },
      userForms: {
        welcome: '欢迎',
        noAccount: '没有账号?',
        signUp: '注册',
        login: '登录',
        forgotPassword: '忘记密码?',
        resetPassword: '重置密码',
        password: '密码',
        email: '电子邮箱',
        emailPlaceholder: '请输入您的电子邮箱',
        passwordPlaceholder: '请输入您的密码',
        bySigningIn: '通过登录，您同意我们的',
        register: '注册',
        alreadyHaveAccount: '已有账号?',
        repeatPassword: '重复密码',
        repeatPasswordPlaceholder: '重复您的密码',
        continueWithGoogle: '继续使用 Google',
        continueWithTwitter: '继续使用 Twitter / X',
        backTo: '返回',
        sendEmail: '发送电子邮件',
        terms: '使用条款',
      },
      tables: {
        topGainers: '热门币种 / 24H',
        topVotedToday: '今日热门投票',
        newListings: '最新广告',
        recentNews: '最新新闻',
        watchlistFavorites: '收藏夹',
      }
    }
  }
}))